
:root{
    --themePrimary:#00458A;
}

*{
    font-family: 'Lato', sans-serif;
    box-sizing: border-box;
}
.text-right{
    text-align: right;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.grid {
    max-width: 188px;
    width: 188px;
    max-height: 94px;
    height: 94px;
    background-color: #fff;
    border-radius: 6px;
    padding: 7px;
    position: relative;
    border: 1px solid #d0d9e1;
    box-shadow: 0px 0px 4px #eaeaea;
}
#printablediv{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 64px;
}
.grid p{
    margin: 0;
}
.assetsName{
    font-size: 12px;
    text-align: right;
}
.qrColm
{
    display: flex;
    justify-content: flex-start;
}
.assetsLocation
{
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 12px;
}
.assetsId
{
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    width: 100%;
    text-align: right;
}
.qrCode
{
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
}
@page:left{
    margin-left: 0cm;
    margin-right: 0cm;
    margin-top: 0cm;
    margin-bottom: 0cm;
}
@page:right{
    margin-left: 0cm;
    margin-right: 0cm;
    margin-top: 0cm;
    margin-bottom: 0cm;
}
.mainLayout
{
    display: flex;
    height: 100vh;
    background-color: #f4f4f4;
}
.leftSide
{
    flex: 1;
    text-align: center;
    align-self: center;
}
.leftSide h1{
    font-size: 32px;
    margin: 0;
    margin-bottom: 0px;
    color: var(--themePrimary);
}
.mainHead{
    text-align: center;
    padding-bottom: 6px;
    padding-top: 4px;
}
.mainHead h1{
    font-size: 36px;
    margin: 0;
    margin-bottom: 0px;
    color: var(--themePrimary);
}
.mainHead .subHeading{
    margin-bottom: 0 !important;
} 
.subHeading{
    margin: 0;
    margin-bottom: 40px;
    font-size: 13px;
    letter-spacing: 2px;
}
.rightSide{
    background-color: #fff;
    padding: 20px;
    position: relative;
}
.chooseBtn{
    border: 0;
    background-color: var(--themePrimary);
    color: #fff;
    padding: 16px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    width: 224px;
    margin-top: 20px;
    height: 55px;
}
.chooseBtn svg{
    width: 32px;
    height: auto;
    vertical-align: -5px;
    margin-right: 5px;
}
.chooseBtn svg path{
    fill: #fff;
}
.GenerateAssetsBtn
{
    border: 0;
    background-color: var(--themePrimary);
    color: #fff;
    padding: 16px 20px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    margin-left: 5px;
    width: 55px;
    height: 55px;
}
.printBtn{
    border: 1px solid var(--themePrimary);
    background-color: #fff;
    color: var(--themePrimary);
    padding: 13px 15px;
    border-radius: 50px;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    width: 125px;
    margin-left: 7px;
}
.printBtn svg{
    width: 22px;
    height: auto;
    vertical-align: -5px;
    margin-right: 5px;
} 

.medLogo
{
    display: none;
}
.logoHeader{
    position: absolute;
    top:10px;
    left: 10px;
    width: 138px;
}
.table{
    width: 100%;    
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #000;
    background-color: #d3d3d3;
}
.table,.table tr, .table th, .table td {
    border: 1px solid #ccc;
    border-collapse: collapse;
}
.table td{
    font-size: 14px;
    padding: 8px 6px;
    border: 0;
}
.table th{
    font-size: 14px;
    padding: 10px;
} 
.table th:first-child{
    border-radius: 8px 0 0 0;
}
.table tr{
    border-collapse: collapse;
}
.table thead tr{
    border-bottom: 1px solid #ccc;
}
.table tbody tr:nth-child(even){
    background-color: #f0f0f0;
}
.table tbody tr:nth-child(odd){
    background-color: #fff;
}
.borderBox{
    border: 1px solid #D3D3D3;
    border-radius: 16px;
    padding: 30px;
    width: 480px;
    margin: 0 auto;
}
.viewData{
    border: 0;
    background-color: transparent;
    color: var(--themePrimary);
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}
.m0{margin: 0;}
.mb0{margin-bottom: 0;}
.mt0{margin-top: 0;}
.ml0{margin-left: 0;}
.mr0{margin-right: 0;}
.p0{padding: 0;}
.pl0{padding-left: 0;}
.pr0{padding-right: 0;}
.pt0{padding-top: 0;}
.pb0{padding-bottom: 0;}
.overlay{
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    height: 124vh;
    background-color: #000000bf;
    z-index: 9;
}
.modalPopup{
    width: 90%;
    background-color: #fff;
    z-index: 10;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 90vh;
    overflow-y: scroll;
}
.modalClose{
    float: right;
    z-index: 1;
    position: relative;
}

.errorMsg{
    color: red;
    animation: headShake 1s linear 0s infinite alternate;
}
@keyframes headShake{
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
    }      
    20%, 80% {
        transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(4px, 0, 0);
    }
}

.lazyLoad
{
    -webkit-animation: 1.5s ease 0s normal forwards 1 fadein;
    animation: 1.5s ease 0s normal forwards 1 fadein;
    position: relative;
}
@keyframes fadein{
    0% { opacity:0; margin-top: 150px; }
    /* 66% { opacity:0.6; margin-top: 66px;} */
    100% { opacity:1; margin-top: 0px;}
}

@-webkit-keyframes fadein{
    0% { opacity:0; margin-top: 150px;}
    /* 66% { opacity:0.6; margin-top: 66px;} */
    100% { opacity:1; margin-top: 0px;}
}

.fadeInUp{
    -webkit-animation: 1.5s ease 0s normal forwards 1 fadeInUp;
    animation: 1.5s ease 0s normal forwards 1 fadeInUp;
    position: relative;
}
@keyframes fadeInUp{
    0% { opacity:0; top: 50px; }
    /* 66% { opacity:0.6; margin-top: 66px;} */
    100% { opacity:1; top: 0px;}
}

@-webkit-keyframes fadeInUp{
    0% { opacity:0; top: 50px;}
    /* 66% { opacity:0.6; margin-top: 66px;} */
    100% { opacity:1; top: 0px;}
}

.fadeInRight{
    -webkit-animation: 1.5s ease 0s normal forwards 1 fadeInRight;
    animation: 1.5s ease 0s normal forwards 1 fadeInRight;
}
@keyframes fadeInRight{
    0% { opacity:0; right: 80px; }
    /* 66% { opacity:0.6; margin-top: 66px;} */
    100% { opacity:1; right: 20px;}
}

@-webkit-keyframes fadeInRight{
    0% { opacity:0; right: 80px;}
    /* 66% { opacity:0.6; margin-top: 66px;} */
    100% { opacity:1; right: 20px;}
}