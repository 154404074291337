.tileBox {
    width: 154px;
    flex-basis: 154px;
    height: 113px;
    display: flex;
    padding: 20px 15px 20px 15px;
    box-sizing: border-box;
    border-radius: 16px;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;
    margin-bottom: 10px;
    max-width: 154px;
}

.link-btn:hover {
    background-color: #f0f7f7;
    color: #025e5e;
}

.tileValue {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 19px;
    font-weight: 500;
}

.tileTitle {
    font-size: 12px;
    letter-spacing: 0px;
    line-height: 19px;
}

// @media screen and (max-width: 1300px) {
//     .tileBox {
//         flex-basis: 18.5%;
//         width: 18.5%;
//     }
// }